import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"

const LAutoEcolePage = ({ data }) => (
  <Layout>
    <Seo title="L'auto-école" breadcrumb={[
      { url: "l-auto-ecole", name: "L'auto-école"},
    ]} />
    <Breadcrumb paths={[{url: null, name:"L'auto-école"}]} />
    <h1 className="h1">L'auto-école</h1>
    <div className="text md:w-3/4 html-content" dangerouslySetInnerHTML={{ __html: data.strapiLAutoEcole.content }}></div>
  </Layout>
)

export default LAutoEcolePage

export const pageQuery = graphql`
  query AutoEcoleQuery {
    strapiLAutoEcole {
      content
    }
  }
`
